import './styles/App.css';
import Body from "./components/Body"

function App() {

  const buttonNames = [, "About"]

  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Body/>
    </div>
  );
}

export default App;
